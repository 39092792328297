import { Component } from "react";

import Utils from "./Utils.js";

class InputBoxAndSlider extends Component {
    state = {
        defaultValue: null
    }

    constructor(props) {
        super(props);
        this.state.defaultValue = Number(this.props.value);
        this.onChange = this.onChange.bind(this);
        this.setDefault = this.setDefault.bind(this);
        this.setMax = this.setMax.bind(this);
    }

    onChange(event) {
        let newValue = event.currentTarget.value.replaceAll(",", "");
        if (isNaN(newValue) || newValue.toString().startsWith(0)) {
            return;
        }

        if (newValue < 0) newValue = 0;
        if (newValue > this.props.max) newValue = this.props.max;

        this.props.parentUpdate(newValue);
    }

    setDefault(event) {
        this.props.parentUpdate(this.state.defaultValue);
    }

    setMax(event) {
        this.props.parentUpdate(this.props.max);
    }

    render() 
    {
        return (
            <div className="input-box-slider">
                <div className="title"> {this.props.title} </div>
                <div className="slider-input">
                    <span className="slider-label left"> {this.props.min} </span>
                    <div className="slidecontainer">
                        <input 
                            className="slider"
                            type="range"
                            min={this.props.min}
                            max={this.props.max}
                            value={Number(this.props.value)}
                            onChange={this.onChange}
                        ></input>
                    </div>
                    <span className="slider-label right" onClick={this.setMax}> {Utils.numberWithUnits(this.props.max)} </span>
                </div>
                <div className="input-box-wrap">
                    <input 
                        className="input-box"
                        type="text"
                        min={0} 
                        max={this.props.max}
                        value={Utils.numberWithCommas(this.props.value)}
                        onChange={this.onChange}
                    ></input>
                    {this.props.description && <button className="button" onClick={this.setDefault}>
                        DEFAULT
                    </button>}
                </div>
                {this.props.description && 
                    <div className="description small-text">
                        {this.props.description}
                    </div>
                }
                
            </div>
        )
    }
}

export default InputBoxAndSlider;