import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route }  from "react-router-dom";
import config from "./config.json";
import axios from "axios";

import Header from "./Header.js";
import Statistics from "./Statistics.js";
import Calculator from "./Calculator.js";

import "./css/Global.css";
import "./css/Header.css";

class App extends Component {
    state = {
        loading: true,
        data: null
    }

    async componentDidMount()
    {
        const data = (await axios.get(`${config.serverUrl}/api/getData`)).data;
        this.setState({ loading: false, data });
    }

    render() 
    {
        return (
            <Router>
                <Header/>
                <div id="main">
                    {(!this.state.loading &&
                        <Switch>
                            <Route exact path="/" render={(props) => 
                                <div>
                                    <Statistics data={this.state.data}/>
                                </div>
                            }/>

                            <Route exact path="/calculator" render={(props) => 
                                <div>
                                    <Calculator data={this.state.data}/>
                                </div>
                            }/>
                        </Switch>
                    )}
                </div>
            </Router>
        );
    }
}

export default App;