import { Component } from "react";
import { Bar } from "react-chartjs-2";

import Utils from "../Utils.js";

import TimeAgo from "javascript-time-ago";
import en from 'javascript-time-ago/locale/en.json'
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en");

class BarsByLockDays extends Component {
    state = {
        activeIndex: 19,
        activeSort: 0,
        currency: "VR"
    }

    constructor(props) {
        super(props);
        this.sortComparer = this.sortComparer.bind(this);
    }

    getDataByLockDays() {
        const dataByLockDays = []

        for (const i in this.props.data.stakers) {
            for (const stake of this.props.data.stakers[i]) {
                if (stake.unstakeTx) continue;

                let index = Math.floor(Number(stake.lockDays) / 180);
                if (index > 19) index = 19;
                stake.staker = i;
                
                if (!dataByLockDays[index]) {
                    dataByLockDays[index] =  {
                        stakes: [],
                        amount: 0
                    }
                }
                dataByLockDays[index].stakes.push(stake);
                dataByLockDays[index].amount += Number(stake.amount);
            }
        }

        for (const i in dataByLockDays) {
            dataByLockDays[i].stakes = dataByLockDays[i].stakes.sort((a, b) => Number(b.shares) - Number(a.shares));
        }

        return dataByLockDays;
    }

    getLabels() {
        const labels = [];
        for (let i = 0.5; i <= 10; i += 0.5) {
            labels.push(`${i} years`);
        }
        return labels;
    }

    sortChanged(column) {
        let activeSort = this.state.activeSort;
        if (column == 0) {
            if (activeSort >= 1) {
                activeSort = 0;
            } else {
                activeSort = 1;
            }
        } else {
            if (activeSort <= 2) {
                activeSort = 3;
            } else {
                activeSort = 2;
            }
        }
        this.setState({ activeSort });
    }

    sortComparer(a, b) {
        switch (this.state.activeSort) {
            case 0:
                return Number(b.shares) - Number(a.shares);
            case 1:
                return Number(a.shares) - Number(b.shares);
                break;
            case 2:
                return Number(a.lockTimestamp) - Number(b.lockTimestamp);
                break;
            case 3:
                return Number(b.lockTimestamp) - Number(a.lockTimestamp);
                break;
        }
    }

    setCurrency(currency) {
        this.setState({ currency });
    }

    render() 
    {
        const dataByLockDays = this.getDataByLockDays();
        const labels = this.getLabels();

        let tableDataSource = []; 
        if (this.state.activeIndex == -1) {
            for (let i = 0; i < 20; i++) {
                tableDataSource = tableDataSource.concat(dataByLockDays[i].stakes);
            }
        } else {
            tableDataSource = dataByLockDays[this.state.activeIndex].stakes;
        }   

        const totalStaked = tableDataSource.reduce((a, b) => a + Number(b.amount), 0);

        const tableData = tableDataSource.sort(this.sortComparer).slice(0, 20).map(obj => {
            return (
                <tr key={obj.stakeTx}>
                    <td> <a href={`https://etherscan.io/tx/${obj.stakeTx}`} target="_blank"> {Utils.fancyHash(obj.stakeTx)} </a> </td>
                    <td> <a href={`https://etherscan.io/address/${obj.staker}`} target="_blank"> {Utils.fancyHash(obj.staker)} </a> </td>
                    <td> {(this.state.currency == "USD" ? "$" : "") + Utils.fancyNumber(Number(obj.amount) * (this.state.currency == "USD" ? this.props.data.price : 1))} </td>
                    <td> {obj.lockDays} </td>
                    <td> {Utils.fancyNumber(Number(obj.shares))} </td>
                    <td> {timeAgo.format(Number(obj.lockTimestamp) * 1000)} </td>
                </tr>
            );
        });
        
        const that = this;

        let sortIcon1;
        let sortIcon2;
        switch (this.state.activeSort) {
            case 0:
                sortIcon1 = <i className="fa fa-angle-down"></i> 
                sortIcon2 = null;
                break;
            case 1:
                sortIcon1 = <i className="fa fa-angle-up"></i> 
                sortIcon2 = null;
                break;
            case 2:
                sortIcon2 = <i className="fa fa-angle-up"></i> 
                sortIcon1 = null;
                break;
            case 3:
                sortIcon2 = <i className="fa fa-angle-down"></i> 
                sortIcon1 = null;
                break;
        }

        //const 
        
        return (
            <div>
                <div className="chart">
                    <div className="buttons">
                        <button className={"button" + (this.state.currency == "VR" ? " active" : "")} onClick={() => this.setCurrency("VR")}>
                            VR
                        </button>
                        <button className={"button" + (this.state.currency == "USD" ? " active" : "")} onClick={() => this.setCurrency("USD")}>
                            USD
                        </button>
                    </div>
                    <Bar
                        data={{
                            labels,
                            datasets: [{
                                data: dataByLockDays.map(el => el.amount * (this.state.currency == "USD" ? this.props.data.price : 1)),
                                backgroundColor: "#ffaa00"
                            }]
                        }}
                        options={{
                            onClick: function (evt, element) {
                                const el = this.getElementsAtXAxis(evt);
                                if (el[0]) {
                                    that.setState({ activeIndex: el[0]._index});
                                }
                            },
                            tooltips: {
                                callbacks: {
                                    label: function(tooltipItem, data) {
                                        const prefix = that.state.currency == "USD" ? "$" : "";
                                        const value = data.datasets[0].data[tooltipItem.index];
                                        return prefix + Utils.numberWithCommas(value);
                                    }
                                }
                            },
                            legend: {
                                display: false,
                            },
                            scales: {
                                yAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: `${this.state.currency == "VR" ? "VR staked" : "USD value"} (M)`,
                                        fontColor: "white",
                                        fontSize: "14",
                                        fontFamily: "FONT",
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        fontColor: "white",
                                        userCallback: function(label, index, labels) {
                                            const prefix = that.state.currency == "USD" ? "$" : "";
                                            return prefix + Utils.numberWithUnits(label);
                                        }
                                    }
                                }],
                                xAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: "Staking period (years)",
                                        fontColor: "white",
                                        fontSize: "14",
                                        fontFamily: "FONT",
                                    },
                                    ticks: {
                                        fontColor: "white",
                                        userCallback: function(label, index, labels) {
                                            return label.split(" ")[0];
                                        }
                                    }
                                }],
                            }
                        }} 
                    ></Bar>
                </div>

                <div className="glowing-tabs">
                    <ul>
                        <li> 
                            <div className="value"> {Utils.numberWithUnits(totalStaked)} </div>
                            <div className="label"> VR Staked </div>
                        </li>
                        <li> 
                            <div className="value"> {Utils.numberWithUnits(totalStaked * this.props.data.price)} </div>
                            <div className="label"> USD Value </div>
                        </li>
                    </ul>
                </div>

                <div className="table-wrap">
                    <div className="table-top"> 
                        <span>
                        {this.state.activeIndex == -1 ?
                            `Stakes for 0 - 10 years`
                            :
                            `Stakes for ${this.state.activeIndex * 0.5} - ${this.state.activeIndex * 0.5 + 0.5} years`
                        }</span>
                        
                        <button 
                            className={"button" + (this.state.activeIndex == -1 ? " active" : "")} 
                            onClick={() => this.setCurrency("VR")} onClick={() => this.setState({ activeIndex: -1 })}
                        >
                            total
                        </button>
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <th> TX hash </th>
                                <th> Staker </th>
                                <th> {this.state.currency} amount </th>
                                <th> Staking days </th>
                                <th title="sort" style={{cursor: "pointer"}} onClick={() => this.sortChanged(0)}> Shares {sortIcon1} </th>
                                <th title="sort" style={{cursor: "pointer"}} onClick={() => this.sortChanged(1)}> When {sortIcon2}</th>
                            </tr>
                            {tableData}
                        </tbody>
                    </table>
                    {tableDataSource.length > 20 && 
                        <p> 
                            ... <span className="highlight-text"> {tableDataSource.length} </span> stakes total 
                        </p>
                        }
                </div>
            </div>
        )
    }
}

export default BarsByLockDays;