import { Component } from "react";
import config from "./config.json";
import Utils from "./Utils.js";

import "./css/Calculator.css";

import InputBoxAndSlider from "./InputBoxAndSlider";

class Calculator extends Component {
    state = {
        balance: 100000000,

        sharesBonus: null,
        shares: null,
        shareInPool: null,
        estimatedAPR: null,
        tickets1: null,
        tickets2: null,

        enteredAmount: 0,
        enteredDays: 0,
        enteredTotalShares: 0
    };

    constructor(props) {
        super(props);

        this.state.enteredAmount = this.state.balance / 10;
        this.state.enteredDays = 365;
        this.state.enteredTotalShares = this.props.data.totalShares;

        this.amountChanged = this.amountChanged.bind(this);
        this.daysChanged = this.daysChanged.bind(this);
        this.totalSharesChanged = this.totalSharesChanged.bind(this);
    }

    componentDidMount() {
        this.update();
    }

    calculateShares(amount, lockDays) {
        const longTermBonus = amount * lockDays * config.shareBonusPerYear / 365;
        const stakingMoreBonus = amount * amount * config.shareBonusPer1M / 1e6;
        const shares = amount + longTermBonus + stakingMoreBonus;
        return shares;
    }

    update() {
        const enteredAmount = Number(this.state.enteredAmount);
        const enteredDays = Number(this.state.enteredDays);
        const shares = this.calculateShares(enteredAmount, enteredDays);
        const totalShares = Number(this.state.enteredTotalShares) + shares;

        let currentMonth = 1 + Utils.monthDiff(new Date(config.startTimestamp * 1000), new Date(Date.now() - 86400 * 2000));
        if (currentMonth >= config.monthlyRewards.length) currentMonth = config.monthlyRewards.length - 1;

        const sharesBonus = shares / enteredAmount * 100 - 100; 
        const shareInPool = shares / totalShares * 100;
        const estimatedAPR = shareInPool * config.monthlyRewards[currentMonth] * 12 / enteredAmount;
        const estimatedAverageAPR = shareInPool * config.rewardsPerYear / enteredAmount;
        const estimatedMonthlyRewards = shareInPool * config.monthlyRewards[currentMonth] / 100;
        const tickets1 = Math.floor(shares / config.ticket1);
        const tickets2 = Math.floor(shares / config.ticket2);
        this.setState({ sharesBonus, shares, shareInPool, estimatedAPR, estimatedAverageAPR, estimatedMonthlyRewards, tickets1, tickets2 });
    }

    amountChanged(enteredAmount) {
        this.state.enteredAmount = enteredAmount;
        this.update();
    }

    daysChanged(enteredDays) {
        this.state.enteredDays = enteredDays;
        this.update();
    }

    totalSharesChanged(enteredTotalShares) {
        this.state.enteredTotalShares = enteredTotalShares;
        this.update();
    }
    
    render() 
    {
        return (
            <div id="calculator">
                <InputBoxAndSlider
                    title="VR amount to stake"
                    min={config.minStakeAmount}
                    max={this.state.balance}
                    value={this.state.enteredAmount}
                    parentUpdate={this.amountChanged}
                >
                </InputBoxAndSlider>
                <InputBoxAndSlider
                    title="Number of days you want to lock up for"
                    min={config.minDays}
                    max={config.maxDays}
                    value={this.state.enteredDays}
                    parentUpdate={this.daysChanged}
                >
                </InputBoxAndSlider>

                <ul className="label-value-list">
                    <li>
                        VR Shares Bonus: 
                        <span className="highlight-text">
                            {Utils.fancyNumber(this.state.sharesBonus)}%
                        </span>
                    </li>
                    <li>
                        VR Shares:  
                        <span className="highlight-text">
                            {Utils.fancyNumber(this.state.shares)}
                        </span>
                    </li>
                    <li>
                        Staker’s share in the VR Pool
                        <span className="highlight-text">
                            {Utils.fancyNumber(this.state.shareInPool)}%
                        </span>
                    </li>
                    <br></br>

                    <li>
                        VR Lands Tickets:
                        <span className="highlight-text">
                            {this.state.tickets1 !== null ? this.state.tickets1 : "N/A"}
                        </span>
                    </li>
                    <li>
                        Mystery Airdrops Tickets:
                        <span className="highlight-text">
                            {this.state.tickets2 !== null ? this.state.tickets2 : "N/A"}
                        </span>
                    </li>
                    <br></br>
                    <li>
                        Estimated Annual Rewards:
                        <span className="highlight-text">
                            {Utils.fancyNumber(this.state.estimatedAPR)}%
                        </span>
                    </li>
                    <li>
                        Estimated Annual Rewards (3 Years):
                        <span className="highlight-text">
                            {Utils.fancyNumber(this.state.estimatedAverageAPR)}%
                        </span>
                    </li>
                    <li>
                        Your next estimated rewards:
                        <span className="highlight-text">
                            {Utils.fancyNumber(this.state.estimatedMonthlyRewards)} VR
                        </span>
                    </li>
                </ul>
                <br></br>
                <InputBoxAndSlider
                    title="Total VR Shares"
                    min={0}
                    max={this.props.data.totalShares * 4}
                    value={this.state.enteredTotalShares}
                    parentUpdate={this.totalSharesChanged}
                    description="the default value represents the actual, real value"
                >
                </InputBoxAndSlider>
            </div>
        )
    }
}

export default Calculator;